<script lang="ts" setup></script>

<template>
    <svg
        width="342"
        height="290"
        viewBox="0 0 342 290"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_494_21806)">
            <path
                opacity="0.8"
                d="M356.994 -259.271C368.373 -236.936 396.676 -223.222 408.056 -200.885C419.434 -178.549 413.888 -147.59 425.267 -125.255C436.646 -102.919 464.95 -89.2045 476.329 -66.8687C487.707 -44.5327 482.161 -13.5741 493.54 8.76175C504.919 31.0973 533.223 44.8119 544.602 67.1477C555.98 89.4837 550.434 120.442 561.813 142.778C573.192 165.114 601.496 178.828 612.875 201.164C624.253 223.5 618.707 254.459 630.086 276.795L605.58 289.279C594.201 266.943 599.747 235.984 588.368 213.649C576.989 191.313 548.685 177.598 537.307 155.263C525.928 132.927 531.474 101.968 520.095 79.6322C508.716 57.2966 480.412 43.582 469.034 21.2462C457.655 -1.08975 463.201 -32.0484 451.822 -54.3842C440.443 -76.7198 412.139 -90.4344 400.761 -112.77C389.382 -135.106 394.928 -166.065 383.549 -188.401C372.17 -210.736 343.866 -224.451 332.488 -246.787L356.994 -259.271Z"
                fill="white"
            />
            <path
                opacity="0.7"
                d="M313.422 -237.075C324.8 -214.739 353.104 -201.026 364.483 -178.689C375.862 -156.353 370.316 -125.394 381.695 -103.058C393.074 -80.7229 421.377 -67.0084 432.756 -44.6726C444.134 -22.3366 438.589 8.62196 449.968 30.9577C461.347 53.2934 489.65 67.0078 501.029 89.3436C512.407 111.68 506.862 142.638 518.24 164.974C529.62 187.31 557.923 201.024 569.302 223.36C580.68 245.696 575.135 276.654 586.513 298.99L562.008 311.474C550.63 289.138 556.175 258.18 544.797 235.844C533.418 213.508 505.114 199.794 493.735 177.458C482.357 155.122 487.902 124.163 476.524 101.828C465.145 79.492 436.841 65.7776 425.462 43.4418C414.084 21.1058 419.63 -9.8528 408.251 -32.1886C396.872 -54.5242 368.568 -68.2387 357.189 -90.5745C345.811 -112.91 351.357 -143.869 339.978 -166.205C328.599 -188.54 300.295 -202.255 288.916 -224.591L313.422 -237.075Z"
                fill="white"
            />
            <path
                opacity="0.6"
                d="M269.84 -214.872C281.218 -192.536 309.522 -178.823 320.901 -156.486C332.28 -134.15 326.734 -103.191 338.113 -80.8554C349.492 -58.5198 377.795 -44.8055 389.174 -22.4697C400.552 -0.133752 395.007 30.8248 406.385 53.1606C417.764 75.4962 446.068 89.2106 457.447 111.546C468.825 133.882 463.28 164.841 474.658 187.177C486.037 209.512 514.341 223.227 525.72 245.562C537.098 267.898 531.552 298.857 542.931 321.193L518.426 333.677C507.047 311.341 512.593 280.382 501.214 258.046C489.835 235.711 461.531 221.996 450.153 199.661C438.774 177.325 444.32 146.366 432.941 124.03C421.562 101.695 393.259 87.9804 381.88 65.6446C370.502 43.3086 376.047 12.3501 364.669 -9.98568C353.29 -32.3213 324.986 -46.0356 313.607 -68.3714C302.229 -90.7074 307.775 -121.666 296.396 -144.002C285.017 -166.337 256.713 -180.052 245.334 -202.388L269.84 -214.872Z"
                fill="white"
            />
            <path
                opacity="0.5"
                d="M226.268 -192.676C237.647 -170.34 265.951 -156.626 277.33 -134.289C288.708 -111.953 283.163 -80.995 294.541 -58.6592C305.92 -36.3236 334.224 -22.6094 345.603 -0.273596C356.981 22.0624 351.435 53.0208 362.814 75.3566C374.193 97.6923 402.497 111.407 413.875 133.742C425.254 156.078 419.708 187.037 431.087 209.373C442.466 231.708 470.769 245.422 482.148 267.758C493.526 290.094 487.981 321.052 499.359 343.388L474.854 355.872C463.476 333.536 469.021 302.578 457.643 280.242C446.264 257.906 417.96 244.192 406.581 221.856C395.203 199.52 400.749 168.562 389.37 146.226C377.991 123.891 349.687 110.176 338.309 87.8405C326.93 65.5046 332.476 34.5461 321.097 12.2103C309.718 -10.1253 281.415 -23.8395 270.036 -46.1753C258.657 -68.5112 264.203 -99.4697 252.825 -121.806C241.446 -144.141 213.142 -157.855 201.763 -180.191L226.268 -192.676Z"
                fill="white"
            />
            <path
                opacity="0.4"
                d="M182.697 -170.478C194.076 -148.143 222.379 -134.429 233.759 -112.092C245.137 -89.7563 239.591 -58.7979 250.97 -36.4621C262.349 -14.1264 290.653 -0.412314 302.031 21.9235C313.41 44.2594 307.864 75.2178 319.243 97.5536C330.621 119.889 358.925 133.603 370.304 155.939C381.682 178.275 376.136 209.234 387.515 231.569C398.894 253.905 427.198 267.619 438.577 289.955C449.955 312.291 444.409 343.249 455.788 365.585L431.282 378.069C419.904 355.733 425.45 324.775 414.071 302.439C402.692 280.103 374.388 266.389 363.01 244.053C351.631 221.717 357.177 190.759 345.799 168.423C334.42 146.087 306.116 132.373 294.737 110.038C283.359 87.7017 288.905 56.7432 277.526 34.4074C266.147 12.0718 237.843 -1.64232 226.465 -23.9781C215.086 -46.314 220.632 -77.2724 209.253 -99.6082C197.874 -121.944 169.571 -135.658 158.192 -157.994L182.697 -170.478Z"
                fill="white"
            />
            <path
                opacity="0.3"
                d="M139.126 -148.282C150.504 -125.946 178.808 -112.233 190.187 -89.8959C201.566 -67.56 196.02 -36.6017 207.399 -14.2659C218.777 8.06981 247.081 21.7838 258.46 44.1196C269.838 66.4555 264.292 97.4138 275.671 119.75C287.05 142.085 315.354 155.799 326.732 178.135C338.111 200.471 332.565 231.429 343.944 253.765C355.322 276.101 383.626 289.815 395.005 312.151C406.383 334.486 400.837 365.445 412.216 387.781L387.711 400.265C376.332 377.929 381.878 346.97 370.5 324.634C359.121 302.299 330.817 288.585 319.438 266.249C308.06 243.913 313.606 212.955 302.227 190.619C290.848 168.283 262.544 154.569 251.166 132.233C239.787 109.898 245.333 78.9392 233.954 56.6035C222.576 34.2678 194.272 20.5538 182.893 -1.78203C171.515 -24.1179 177.061 -55.0762 165.682 -77.412C154.303 -99.7477 125.999 -113.462 114.621 -135.798L139.126 -148.282Z"
                fill="white"
            />
            <path
                opacity="0.2"
                d="M95.5544 -126.085C106.933 -103.749 135.237 -90.0355 146.616 -67.6986C157.995 -45.3628 152.449 -14.4045 163.827 7.93128C175.206 30.267 203.51 43.9809 214.889 66.3167C226.267 88.6525 220.721 119.611 232.1 141.947C243.478 164.282 271.782 177.996 283.161 200.332C294.539 222.668 288.993 253.626 300.372 275.962C311.751 298.298 340.055 312.011 351.433 334.347C362.812 356.683 357.266 387.641 368.644 409.977L344.139 422.461C332.761 400.125 338.307 369.167 326.928 346.831C315.549 324.496 287.245 310.782 275.867 288.446C264.488 266.11 270.034 235.152 258.656 212.816C247.277 190.48 218.973 176.766 207.594 154.431C196.216 132.095 201.762 101.136 190.383 78.8006C179.004 56.4649 150.701 42.751 139.322 20.4152C127.943 -1.92061 133.489 -32.8789 122.111 -55.2147C110.732 -77.5504 82.4281 -91.2643 71.0495 -113.6L95.5544 -126.085Z"
                fill="white"
            />
            <path
                opacity="0.15"
                d="M51.9714 -103.883C63.3501 -81.5468 91.6539 -67.8333 103.033 -45.4965C114.412 -23.1606 108.865 7.79757 120.245 30.1344C131.624 52.4701 159.927 66.1839 171.306 88.5208C182.685 110.857 177.139 141.815 188.518 164.151C199.896 186.486 228.2 200.2 239.579 222.537C250.958 244.873 245.412 275.831 256.791 298.168C268.17 320.504 296.474 334.217 307.853 356.554C319.231 378.89 313.685 409.848 325.064 432.184L300.558 444.668C289.18 422.332 294.726 391.374 283.347 369.038C271.969 346.702 243.665 332.989 232.286 310.652C220.907 288.316 226.453 257.358 215.074 235.021C203.695 212.685 175.391 198.971 164.012 176.635C152.634 154.299 158.18 123.341 146.801 101.005C135.422 78.669 107.119 64.9552 95.7393 42.6184C84.3608 20.2825 89.9069 -10.6757 78.5282 -33.0115C67.1495 -55.3472 38.8457 -69.061 27.4665 -91.3978L51.9714 -103.883Z"
                fill="white"
            />
            <path
                opacity="0.1"
                d="M8.4002 -81.6852C19.7789 -59.3494 48.0826 -45.6359 59.4619 -23.2991C70.8406 -0.963277 65.2942 29.9949 76.6734 52.3317C88.0522 74.6675 116.356 88.3811 127.735 110.718C139.114 133.054 133.568 164.012 144.947 186.349C156.325 208.684 184.629 222.398 196.008 244.735C207.387 267.071 201.841 298.029 213.22 320.366C224.599 342.702 252.903 356.415 264.282 378.752C275.66 401.088 270.114 432.046 281.493 454.383L256.988 466.867C245.609 444.531 251.156 413.573 239.776 391.236C228.398 368.9 200.094 355.187 188.715 332.85C177.336 310.514 182.882 279.556 171.503 257.219C160.124 234.883 131.821 221.17 120.441 198.833C109.063 176.497 114.609 145.539 103.23 123.202C91.851 100.866 63.5473 87.1525 52.1681 64.8157C40.7894 42.4799 46.3357 11.5217 34.9565 -10.8151C23.5777 -33.1509 -4.72603 -46.8645 -16.1053 -69.2014L8.4002 -81.6852Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_494_21806">
                <rect
                    width="488.671"
                    height="451.94"
                    fill="white"
                    transform="translate(403 -212) rotate(63.004)"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped></style>
