<script lang="ts" setup>
interface Props {
    color?: 'yellow' | 'blue' | 'gray' | 'white';
    waves?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: 'blue',
  waves: true,
})

const cardClasses = computed(() => {
  return {
    'bg-gradient-to-r from-uk-blue to-[#85B3FE]': props.color === 'blue',
    'bg-gradient-to-r from-[#FDB447] to-[#FDEE50]':
            props.color === 'yellow',
    'bg-uk-white': props.color === 'gray',
    'bg-white': props.color === 'white',
  }
})
</script>

<template>
    <div
        class="p-8 lg:p-12 xl:py-12 xl:px-16 text-white rounded-xl flex flex-col items-start justify-between gap-y-12 relative"
        :class="cardClasses"
    >
        <aside
            v-if="props.waves"
            class="absolute inset-y-0 right-0 z-0 max-h-full w-7/12 sm:w-3/5 md:w-1/2 lg:w-fit lg:max-w-[50%] overflow-hidden"
        >
            <IconWaves class="relative w-fit min-h-full" />
        </aside>

        <main class="z-10 w-full">
            <slot />
        </main>
    </div>
</template>

<style scoped></style>
